body {
  margin: 0;
  font-family: 'CircularStd', sans-serif;
  font-size: 17px;
  line-height: 1.618;
}

#root {
  overflow-x: hidden;
}

a {
  color: #0017C6;
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  opacity: .5;
}

h1, h2, h3, h4 {
  margin-top: 0;
}

h1 {
  margin-bottom: 24px;
  font-size: 32px;
}

h4 {
  margin-bottom: 12px;
}

p {
  margin-top: 0;
  margin-bottom: 18px;
  opacity: .7;
}

p:last-child {
  margin-bottom: 0;
}
